
.hero-banner {

	&__img {

		&.is-loading {

			// add a aspect ratio before
			// the images have loaded. This allows the
			// image to fill it's proper height, stopping the
			// page from jumping around.

			padding-top: 70%;
			height: 0;

			@include breakpoint( tb ) {

				padding-top: 33%;

			}

		}

		&.is-flipped {

			transform: scaleX( -1 );

		}

	}

	&__content {

		transform: translateY( rem-calc( -28px ) );

	}

	&__shiv {

		// shiv is a absolutely-centered panel which sits inside
		// the .hero-banner__content container vertically.

		@include breakpoint( tb ) {

			top: 50%;
			transform: translateY( -50% );

		}

	}

}
