

.discover-block {

	&__img {

		&.js-lazy {

			// add a aspect ratio before
			// the images have loaded. This allows the
			// image to fill it's proper height, stopping the
			// page from jumping around.
			padding-top: 62%;
			height: 0;

		}

	}

}
