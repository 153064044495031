

.discover-landing {

	&__prev-spinner {

		transition: all $default-time $lc-ease;

		&:not( .is-active ) {

			margin-top: 0;
			margin-bottom: 0;
			height: 0;

		}

	}

}
