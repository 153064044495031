
.hero-banner {

	&__button,
	&__button.btn--alt {

		color: $black;
		border-color: currentColor;
		background-color: transparent;

		min-width: rem-calc( 180px );

		height: 2.8rem;
		line-height: 2.7rem;

	}

	&:hover &__button,
	&:hover &__button.btn--alt {

		color: $black;
		border-color: $black;
		background-color: transparent;

	}

	@include breakpoint( tb ) {

		&__button.btn--alt {

			color: $white;
			border-color: $white;

		}

		&:hover &__button {

			color: $white;
			border-color: $black;
			background-color: $black;

		}

		&:hover &__button.btn--alt {

			color: $black;
			border-color: $white;
			background-color: $white;

		}

	}

}
