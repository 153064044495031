

.trading-box {

	&__img {

		&.js-lazy {

			// add a 1:1 aspect ratio before
			// the images have loaded. This allows the
			// image to fill it's proper height, stopping the
			// page from jumping around.
			padding-top: 100%;
			height: 0;

		}

	}

}
