
.new-in-block {

	&__img {

		&.is-loading {

			// add a aspect ratio before
			// the images have loaded. This allows the
			// image to fill it's proper height, stopping the
			// page from jumping around.

			padding-top: 65%;
			height: 0;

			@include breakpoint( dt ) {

				padding-top: 77%;

			}

		}

	}

}
