.hero-banner__link,
.new-in-block__link,
.trading-box__link,
.discover-block__link {

	// needed to allow the <a> tag to be
	// clickable in IE9.
	background-color: $ie9-transparent;

}
